<template>
  <v-main class="">
    <ApiErrorMessage :message="apiErrorMessage" />
    <v-dialog
      :attach="true"
      hide-overlay
      v-model="imageSlider"
      max-width="95%" 
      width="auto"     
      margin="0"
    >
      <v-carousel class="carousel-wrapper" :show-arrows="false" hide-delimiters height="auto">
          <span class="carousel-close-icon" @click="imageSlider = false">
            <v-icon>mdi-close</v-icon>
          </span>
        <v-carousel-item v-for="(item, i) in images" :key="i">
          <img
            :src="item.social"
            style="width: 100%; height: 100%"
            :alt="item.social"
            eager
          />
        </v-carousel-item>
      </v-carousel>
    </v-dialog>

    
    <v-dialog
      :attach="true"
      v-model="dialog.open"
      max-width="700px">
      <v-card>
        <v-card-title>{{ dialog.title }}</v-card-title>
        <v-card-text>
          <v-textarea
            v-show="dialog.title === $t('posts.createPost')"
            v-model="message"
            :placeholder="$t('posts.typeYourMessageHere')"
            :error-messages="messageError"
            @blur="$v.message.$touch()"
            outlined
            solo
            name="input-7-4"
            spellcheck="false"
          >
          </v-textarea>
          <v-textarea
            v-show="dialog.title === $t('posts.replyOnPost')"
            v-model="reply"
            :placeholder="$t('posts.enterText')"
            :error-messages="replyError"
            @blur="$v.reply.$touch()"
            outlined
            solo
            name="input-7-4"
            spellcheck="false"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="btn custom-btn red-outline-btn"
            @click="dialog.open = !dialog.open"
          >
            {{ dialog.closeButtonText }}
          </v-btn>
          <v-btn
            class="
              btn custom-btn pattern-btn
            "
            :loading="loading"
            @click="callDialogMethod"
            :disabled="
              (dialog.isForCreatePost &&
                $v.message.$invalid &&
                $v.message.$dirty) ||
              (!dialog.isForCreatePost &&
                $v.reply.$invalid &&
                $v.reply.$dirty)
            "
          >
            {{ dialog.saveButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <div class="post-wrapper">
      <div class="post-topBar d-flex align-center justify-space-between mb-5">
      <h6>Posted Messages</h6>
       <div class="post-topBar-r d-flex">
        <div class="search-wrap">
          <v-text-field

            hide-details="auto"
            v-model="searchText"  
            elevation="0"
            height="40"
            placeholder="Search..."
            dense
            solo
            @input="searchPosts"
          ></v-text-field>
          <div class="d-flex">
            <img src="../assets/images/lucide_search.svg" alt="">
          </div>
        </div>
          <v-btn
            :ripple="false"
            @click="setDialog(false)"
            class="btn custom-btn pattern-btn"
          >
            {{ $t("posts.createPost") }}
          </v-btn>
       </div>
        </div>
      <div class="post-inr">
      <v-data-table
          class="table table-striped"
          :headers="headers"
          :items="posts.data"
          :disable-sort="true"
          :loading="loading"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          hide-default-footer
          :hide-default-header="true"
        >
        <template v-slot:item.id="{ item }" class="post-info">
          <div class="post-info-para">
              <h5 v-if="!item.phone && !item.medias.length && item.post_type==0">
                <pre class="text-pre-wrap">{{ item.message.replace('$0.00', 'Call') }}</pre>
              </h5>
              <div v-if="!item.phone && item.post_type==2" class="text-pre-wrap post-with-images">
                <div class="post-with-images-text"> 
                  <!-- <span class="error--text">For Sale</span><br/> -->
                  <h5>{{ item.message.replace('$0.00', 'Call') }}</h5>
                </div>
              </div>
              <div v-if="item.post_type==3" class="text-pre-wrap post-with-images">
                <div class="post-with-images-text">    
                  <span>{{ item.message.replace('$0.00', 'Call') }}</span>
                </div>
              </div>
              <p class="post-info-para1 mb-0">
              <h5 v-if="item.phone && item.post_type==0"><pre class="text-pre-wrap error--text">{{ item.message.replace('$0.00', 'Call') }}</pre></h5>
              <h5 v-if="item.phone  && item.post_type==1"><pre class="text-pre-wrap blue--text">{{ item.message.replace('$0.00', 'Call') }}</pre></h5>
              <h5 v-if="!item.phone  && item.post_type==1"><pre class="text-pre-wrap blue--text">{{ item.message.replace('$0.00', 'Call') }}</pre></h5>
            </p>
            <p v-if="item.owner && !item.phone" class="post-info-para2 mb-0 mt-1"><strong>By:</strong> {{ item.owner.name }}</p>
            <p v-if="item.owner && item.phone && item.post_type==1" class="post-info-para2 mb-0 mt-1"><strong>By:</strong> {{ item.phone }}</p>
            <p v-if="!item.owner && item.phone && item.post_type==0" class="post-info-para2 mb-0 mt-1"><strong>By:</strong> {{ item.phone }}</p>
          </div>
          <div class="datetime mt-1 mob-show">{{ item.created_at | DateTimezoneFilter }}</div>
        </template>
        <template v-slot:item.message="{ item }" class="align-middle">
          <div class="text-right">
            <div class="datetime mb-2">{{ item.created_at | DateTimezoneFilter }}</div>
          <v-btn
          :ripple="false"
              v-show="item.owner == null || item.owner.id != user.id && (item.post_type==0 || item.post_type==2 || item.post_type==3)"
              :disabled="item.is_replied === true"
              large
              class="btn custom-btn pattern-btn"
              @click="setDialog(true, item)"
            >
              {{
                item.owner == null ? $t("posts.replycall") : $t("posts.reply")
              }}
            </v-btn>
          </div>
        </template>
        </v-data-table>
      </div>
        <div class="text-center mt-5">
          <v-pagination
            v-model="page"
            :length="pageCount"
            @input="fetchPosts"
          ></v-pagination>
        </div>
    </div>
  </v-main>
</template>

<script>
import { debounce } from "lodash";
import dateUtil from "@/utils/date.utils";
import SocketMixin from "@/mixins/SocketMixin";
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { DEBOUNCE_TIMEOUT } from "@/constants/common";
import { ITEMS_PER_PAGE, ADMIN_TEAM_USERS, ATTENTION_POST_IMAGE } from "@/constants/common";

export default {
  name: "Posts",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mixins: [SocketMixin.listeners],
  data() {
    return {
      images: [],
      imageSlider: false,
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 0,
      dialog: {
        isForCreatePost: false,
        title: "",
        closeButtonText: "",
        saveButtonText: "",
        open: false,
        link: "",
      },
      selectedPost: {},
      loading: false,
      post: {
        message: null,
      },
      replyOnPostFields: {
        reply: null,
      },
      message: "",
      badgeCount: 0,
      reply: null,
      menu2: false,
      date: null,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      searchText: "",
      errors: {
        message: null,
        reply: null,
      },
      fileLoader: false,
      image: false,
      adminTeamUser: ADMIN_TEAM_USERS,
      isAttentionPost: false,
      attentionPost: ATTENTION_POST_IMAGE
    };
  },
  validations: {
    message: {
      required,
    },
    reply: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      unReadMessagesCount: "messages/getUnReadMessagesCount",
      user: "user/getUser",
      posts: "posts/getPosts",
      postThreads: "postThread/getPostThreads",
      imageResponse: "posts/getImageResponse",
    }),
    messageError() {
      const errors = [];
      if (!this.$v.message.$dirty) return errors;
      !this.$v.message.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("posts.message"),
          })
        );

      if (this.errors && this.errors.message) {
        return this.errors.message;
      }

      return errors;
    },
    replyError() {
      const errors = [];
      if (!this.$v.reply.$dirty) return errors;
      !this.$v.reply.required &&
        errors.push(
          this.$t("requiredValidation", {
            field: this.$t("posts.reply"),
          })
        );

      if (this.errors && this.errors.reply) {
        return this.errors.reply;
      }

      return errors;
    },

    headers() {
      return [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "id",
          width: "90%",
        },
        {
          text: "",
          value: "message",
          width: "10%",
        }
      ];
    },
  },

  async mounted() {
    await this.getUnReadMessagesCount();
    this.badgeCount = this.unReadMessagesCount;
    await this.fetchPosts();
  },

  methods: {
    ...mapActions({
      getUnReadMessagesCount: "messages/getUnReadMessagesCount",
      getPostThreads: "postThread/getPostThreads",
      getPosts: "posts/getPosts",
      createPost: "posts/createPost",
      createMessages: "messages/createMessages",
      replyMessages: "messages/replyMessages",
      uploadImage: "posts/uploadImage",
      deleteImage: "posts/deleteImage",
    }),

    append(emoji) {
      this.message += emoji;
    },

    setDialog(isRepliedClicked, item = null) {
      this.selectedPost = item;
      if (!isRepliedClicked) {
        this.dialog.isForCreatePost = true;
        this.dialog.title = this.$t("posts.createPost");
        this.dialog.open = true;
        this.dialog.closeButtonText = this.$t("posts.cancel");
        this.dialog.saveButtonText = this.$t("posts.create");
      } else {
        this.dialog.isForCreatePost = false;
        this.dialog.title = this.$t("posts.replyOnPost");
        this.dialog.open = true;
        this.dialog.closeButtonText = this.$t("posts.cancel");
        this.dialog.saveButtonText = this.$t("posts.reply");
      }
    },

    callDialogMethod() {
      if (this.dialog.title === this.$t("posts.createPost")) {
        this.storePost();
      } else {
        this.replyOnPost(this.selectedPost);
      }
    },

    searchPosts: debounce(function () {
      this.fetchPosts();
    }, DEBOUNCE_TIMEOUT),

    async fetchPosts() {
      this.loading = true;
      try {
        if (this.searchText) {
          this.page = 1;
        }

        const startDate = this.date
          ? dateUtil.getStartDate(this.date)
          : dateUtil.getOneMonthBackDate();
        const endDate = this.date
          ? dateUtil.getEndDate(this.date)
          : dateUtil.getCurrentDate();

        await this.getPosts({
          page: this.page,
          items_per_page: ITEMS_PER_PAGE,
          search: this.searchText,
          start_date: startDate,
          end_date: endDate,
        });
        if (this.posts.meta) {
          this.itemsPerPage = parseInt(this.posts.meta.per_page);
          this.pageCount = this.posts.meta.last_page;
        } else {
          this.itemsPerPage = this.posts.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    async storePost() {
      this.$v.message.$touch();
      if (!this.$v.message.$invalid) {
        this.loading = true;
        try {
          var payload = { message: this.message };
          var media_ids = this.image ? this.image.id : '';
          if(media_ids != '') {
            payload = {
              ...payload,
              media_ids: [media_ids]
            }
          }
          await this.createPost(payload);
          this.message = "";
          this.$v.message.$reset();
          this.$refs['img1'].$el.children[1].value = "";
          this.image = false;
        } catch ({ message }) {
          this.errors.message = message.message;
        } finally {
          this.loading = false;
          this.dialog.open = false;
        }
      }
    },

    async replyOnPost(post) {
      this.$v.reply.$touch();
      if (!this.$v.reply.$invalid) {
        this.loading = true;
        try {
          if (!post.owner) {
            await this.replyMessages({
              post_id: post.id,
              body: this.reply,
            });
          } else {
            await this.createMessages({
              post_id: post.id,
              recipient_id: post.owner.id,
              body: this.reply,
            });
          }
          await this.fetchPosts();
          await this.getPostThreads({
            page: 1,
            items_per_page: ITEMS_PER_PAGE,
            search: "",
          });
          this.reply = "";
          this.expanded = [];
          this.$v.reply.$reset();
        } catch ({ message }) {
          this.errors.reply = message.body;
        } finally {
          this.dialog.open = false;
          this.loading = false;
        }
      }
    },

    setImageSlider(item) {
      if(item.post_type == 3) {
        item.medias[0].social = item.medias[0].url700;
      }
      this.imageSlider = true;
      this.images = item.medias;
    },

    async onFileChange(fileData, type) {
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image = selectedImage.data;
    },

    async onCheckAttentionPost() {
      if(this.isAttentionPost) {
        this.image = ATTENTION_POST_IMAGE;
      } else {
        this.image = false;
      }
    },

    async saveImage(file, tag) {
      try {
        this.fileLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", this.user.user_detail.company_name);
        await this.uploadImage(formData);
        this.fileLoader = false;
        return this.imageResponse;
      } catch ({ message }) {
        this.$refs[tag].$el.children[1].value = "";
        this.apiErrorMessage = message;
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      this.image = false;
      if(this.isAttentionPost) {
        this.isAttentionPost = false;
      }
    }
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
.post-inventory-div{
  display: flex;
  align-items: center;
}
</style>
