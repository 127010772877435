<template>
  <div class="fill-height">
    <Navbar />
    <div class="main-body custom-height">
      <div class="box-wrapper">
        <div class="box-header d-flex justify-space-between align-center">
          <div class="box-header-l d-flex align-center">
            <label class="switch">
              <input
                type="checkbox"
                v-model="broadcasteNow"
                @change="onchange(broadcasteNow)"
              /><span class="switch-slider switch-slider-round"></span>
            </label>
            <h6 id="toggle_cont">{{ switchlabel }}</h6>
          </div>
          <div class="box-header-r">
            <h4>
              Online Members
              <span class="badge badge-bg" id="participantCount">0</span>
            </h4>
          </div>
        </div>
        <CurrentBroadcasts />
      </div>
      <div class="custom-height mt-10">
        <Posts />
      </div>
    </div>
    <Footer></Footer>
    <v-dialog
      :value="user.is_on_grace_period && dialog && !isAssociate"
      persistent
      max-width="430"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("payment.notificationAlert")
        }}</v-card-title>
        <v-card-text class="vcardtext"
          ><v-alert v-if="user.has_failed_payment" dense outlined type="error">
            {{ user.failed_payment_reason }}</v-alert
          >{{ $t("payment.gracePeriondMessage") }}
          <!-- {{ user.grace_period_days }}
          {{ $t("payment.days") }}-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green accent-4"
            class="green darken-4 green-gradient white--text"
            @click="
              dialog = false;
              $router.push('/payment-details');
            "
          >
            {{ $t("payment.paynow") }}
          </v-btn>
          <v-btn
            color="red accent-4"
            class="red darken-4 red-gradient white--text"
            @click="dialog = false"
          >
            {{ $t("payment.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <chat
      :show="openChat"
      @countUnseen="getUnseen"
      @updateShow="openChat = false"
      v-if="currentRouteName.includes('dashboard')"
    />
    <div class="chat-w">
      <div
        id="chat"
        style="cursor: pointer"
        @click="openChat = true"
        v-if="currentRouteName.includes('dashboard') && user.user_type != 3"
      >
        <img
          class="chaticon-img"
          src="../assets/images/chat-icon-new.svg"
          data-bs-toggle="modal"
          data-bs-target="#chatModal"
          aria-label="Chat Icon"
        />
        <v-badge
          color="red"
          class="mb-6"
          :content="badgeCount"
          v-if="badgeCount !== 0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import Posts from "../components/Posts";
import { switchOn } from "@/plugins/yoyo.js";
import "@/plugins/echo";
// Janus Related files
import "@/plugins/yoyo";
import { mapGetters } from "vuex";
import chat from "../components/chat/Chat";
import EventBus from "../bus/eventBus";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    Posts,
    chat,
  },

  data() {
    return {
      dialog: true,
      broadcasteNow: false,
      openChat: false,
      badgeCount: 0,
      switchlabel: "Click to talk",
    };
  },

  watch: {
    broadcasteNow: function (val) {
      console.log("val", val);
      if (val == true) {
        this.switchlabel = "Click to hang up";
      } else {
        this.switchlabel = "Click to talk";
      }
    },
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
    currentRouteName() {
      return this.$route.path;
    },
  },
  async mounted() {
    EventBus.$on("PARTICIPANT_COUNT", function (count) {
      sessionStorage.setItem("onlineUsers", Number(count));
      console.log("onlineUsers=============>" + count);
      document.getElementById("participantCount").innerText = Number(count);
    });
  },
  mounted() {
    window.addEventListener(
      "storage",
      () => {
        // window.location.reload();
      },
      false
    );

    if (!localStorage.getItem("user_data")) {
      localStorage.removeItem("access_token");
      // window.location.reload();
    }
  },
  methods: {
    onchange() {
      switchOn(this.broadcasteNow);
    },
    getUnseen(data) {
      if (data != 0 && data != this.badgeCount) {
        const sound = require("../assets/chattone.mp3");
        new Audio(sound).play();
      }
      this.badgeCount = data;
    },
  },
};
</script>
